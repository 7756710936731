<template>
  <ServiceLayout>
    <v-sheet width="370" class="px-6 py-8" rounded>
      <h6 class="text-h6 text-center mb-7">Aktywacja konta</h6>
      <RepeatPassword ref="form" />
      <v-btn
        :loading="loading"
        @click="confirmAccount"
        @keyup.native.enter="confirmAccount"
        type="submit"
        large
        rounded
        block
        color="primary"
      >
        Aktywuj konto
      </v-btn>
    </v-sheet>
  </ServiceLayout>
</template>

<script>
import RepeatPassword from '@/components/UI/RepeatPassword'
import ServiceLayout from '@/layouts/ServiceLayout'

import RepositoryFactory from '@/repositories/RepositoryFactory'

const AccountRepository = RepositoryFactory.get('account')

export default {
  name: 'AccountConfirm',
  components: {
    ServiceLayout,
    RepeatPassword,
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async confirmAccount() {
      const passwords = await this.$refs.form.validate()
      if (passwords) {
        this.loading = true
        try {
          await AccountRepository.confirmAccount({
            ...this.$route.query,
            ...passwords,
          })
          this.loading = false
          this.$router.push('/login')
        } catch {
          this.loading = false
        }
      }
    },
  },
}
</script>

<style scoped></style>
