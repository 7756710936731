<template>
  <v-form ref="form" lazy-validation>
    <PasswordInput
      @keydown.prevent.space
      error-count="5"
      :rules="[...$rules.required, ...$rules.password]"
      v-model.trim="password"
      outlined
      dense
      label="Hasło"
      name="password"
    />
    <PasswordInput
      validate-on-blur
      @keydown.prevent.space
      error-count="5"
      :rules="[...$rules.required, ...passwordConfirmationRule]"
      v-model.trim="rePassword"
      outlined
      dense
      label="Powtórz hasło"
      name="password"
    />
  </v-form>
</template>

<script>
import PasswordInput from '@/components/UI/PasswordInput'

export default {
  name: 'RepeatPassword',
  components: {
    PasswordInput,
  },
  data() {
    return {
      password: '',
      rePassword: '',
    }
  },
  methods: {
    validate() {
      return new Promise((resolve, reject) => {
        if (this.$refs.form.validate()) {
          resolve({
            password: this.password,
            passwordConfirmation: this.rePassword,
          })
          return
        }
        reject()
      })
    },
  },
  computed: {
    passwordConfirmationRule() {
      return () => this.password === this.rePassword || 'Hasła są różne'
    },
  },
}
</script>

<style scoped></style>
